import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Listings = ({listings}) => { 
  return (
  <div className='listings p40'>
    <div className='grid'>
      {listings.edges?.map((listing, index) => { 
        return (
          <Listing key={index} listing={listing} />
        )
      })}
    </div>
  </div>
  )
}

export default Listings

const Listing = ({listing}) => { 
  listing = listing.node
  return (
    <Link to={'/listing/'+listing.slug} className='link' >
      <div className='listing hover--button flex flex-wrap h-100'>
        <div className='ratio-2-1 bg-grey pos-rel overflow-hidden'>
          <GatsbyImage className='bg-image' image={listing.featureImage?.gatsbyImageData} alt={listing.title} />
          {( listing.firstImage ? 
            <GatsbyImage className='bg-image second-image' image={listing.firstImage?.gatsbyImageData} alt={listing.title} /> : ''
          )}
        </div>
        <div className='title-area p20 b1 uppercase w-100'>
          <p className='m0 strong mb10'>{listing.title}</p>
          <p className='m0 mh-40 m-small'>{listing.caption}</p>
        </div>
        <div className='price p20 b1 bt0 uppercase strong btn w-100'>Buy Now</div>
      </div>
    </Link>
  )
}