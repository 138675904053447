import * as React from "react"
import Layout from "../components/layout"
import Listings from "../components/listings"
import Spacer from "../components/spacer"
import Title from "../components/title"
import News from "../components/news"
import Seo from "../components/seo"
import { graphql } from "gatsby" 

const ListingsPage = ({data}) => {
  return (
  <Layout header='dark'>
    <Spacer className='half' />
    <div className='pth'>
      <Title title={data.page.title}/>
      <Listings listings={data.listings}  />
    </div>
    <Spacer />
    <Title title='Procrastinations' subtitle="What I'm doing when I'm not writing"/>
    <News article={data.news.edges[0].node} />
    <Spacer />
  </Layout>
  )
}

export const Head = () => <Seo title="Listings" />

export default ListingsPage

export const query = graphql`
  query ListingPage {
    page:datoCmsListingsPage {
      title
    }
    listings:allDatoCmsListing(filter: {listingStatus: {eq: "For Sale"}}, sort: {fields: position, order: ASC}) {
      edges {
        node {
          title
          slug
          price
          caption
          featureImage {
            gatsbyImageData
          }
          firstImage {
            gatsbyImageData
          }
        }
      }
    }
    news:allDatoCmsNew(limit: 1) {
      edges {
        node {
          link
          title
          readTime
          featureImage {
            gatsbyImageData
          }
          meta {
            publishedAt(formatString: "D MMMM YYYY")
          }
        }
      }
    }
  }
`